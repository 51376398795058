/* PhoneInput Componet CSS START */
.phoneCustomCss {
    padding-left: 48px !important;
    width: 100% !important;
    line-height: 1.5 !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    border: 2px solid #000000 !important;
    border-radius: 10px !important;
    background-color: #00000000 !important;
    min-height: auto;
    font-weight: 400;
    height: auto !important;
}
.buttonCustomCss{
    z-index: 2;
    background: transparent !important;
    border:0px !important;
    /* border-radius: 3px 0 0 0; */
}
.buttonCustomCss.open{
    z-index:2;
    background:transparent !important;
    border:0px !important;
}
.buttonCustomCss.open .selected-flag{
    background:transparent !important;
    border:0px !important;
}
.selected-flag:hover{
    background: transparent !important;
}
/* PhoneInput Componet CSS END */