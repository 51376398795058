/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.landing-page-container {
  min-height: 100vh;
  background: linear-gradient(167.21deg, #0091CE 8.08%, #FE4A4D 98.99%);
}

.hcp-welcome-container {
  height: 65vh;
  padding-top: 20vh
}

.button-container {
  padding: 0% 15% 0% 15%;
}

.hcp-button {
  background: #FF8486 !important;
  border-radius: 25px !important;
  color: #ffffff !important;
  border-color: #FF8486 !important;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.patient-button {
  background: #E9F7FE !important;
  border-radius: 25px !important;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.hcp-welcome-logo {
  display: block;
  margin: auto;
  width: 60%;
}

.hcp-welcome-title {
  font-size: 30px;
  text-align: center;
  letter-spacing: 0.04em;
}

.hcp-welcome-subtitle {
  text-align: center;
  color: #fe7072;
}

.hcp-login-button {
  margin-top: 20vh;
  background: #ffffff !important;
  border-radius: 25px !important;
  color: #fe898b !important;
  border-color: #fe898b !important;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.hcp-welcome-background {
  min-height: 35vh;
  border-radius: 60% 60% 0% 0% / 30% 30% 60% 40%;
  background: linear-gradient(180deg, #FE7072 0%, #FEBBBC 89.06%);
  padding-top: 20%;
}

.hcp-login-page {
  background-color: #FFC7C8;
  height: 100vh;
}

.hcp-signup-page {
  background-color: #fe7072;
  height: 100%;
}

.hcp-login-container {
  background: linear-gradient(180deg, #FE7072 0%, #FEBBBC 100%);
  min-height: 95vh;
  padding: 10%;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}

.hcp-login-title {
  color: #ffffff;
  font-size: 30px;
  font-style: normal;
}

.hcp-lang {
  height: fit-content;
  color: #ffffff;
  font-size: 20px;
  font-style: normal;
  display: flex;
  justify-content: space-between;
  letter-spacing: 0.04em;
}

.sign-up-subtitle {
  font-size: 12px;
}

.instant-redemption-modal {
  overflow-y: auto;
  height: 95vh;
}

.blue-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  background-color: #C2EBFF;
  border-radius: 20px;
  padding: 32px;
}

.red-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  background-color: #FE7173;
  border-radius: 20px;
  padding: 32px;
}

.tutorial-modal-1 {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  background-color: white;
  border-radius: 20px;
  padding: 32px;
}

.tutorial-modal-2 {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  background-color: white;
  border-radius: 20px;
  padding: 32px;
}

.tutorial-modal-3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  background-color: white;
  border-radius: 20px;
  padding: 32px;
}

.tutorial-modal-4 {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  background-color: white;
  border-radius: 20px;
  padding: 32px;
}

.page-title {
  display: flex;
}

.hcp-signup-lang {
  height: fit-content;
  color: #000000;
  font-size: 20px;
  font-style: normal;
  display: flex;
  justify-content: space-between;
  letter-spacing: 0.04em;
}

.hcp-lang-border {
  border: 2px solid #ffffff;
  border-radius: 10px;
}

.hcp-signup-lang-border {
  border: 2px solid #000000;
  border-radius: 10px;
}

.hcp-email-label {
  font-size: 20px;
  color: #ffffff;
}

.input-field-white {
  color: white !important;
  border: 2px solid #FFFFFF !important;
  border-radius: 10px !important;
  background-color: #00000000 !important;
  padding: 10px 12px !important;
}

.input-field-white::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
}

.input-field-white:focus {
  border-color: #FFFFFF !important;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25) !important;
}

.input-field-black {
  border: 2px solid #000000 !important;
  border-radius: 10px !important;
  background-color: #00000000 !important;
  padding: 10px 12px !important;
}

.input-field-black::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5) !important;
}

.input-field-black:focus {
  border-color: #000000 !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25) !important;
}

.css-1nrlq1o-MuiFormControl-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  width: 100%;
  /* min-width: 0; */
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
}

.hcp-forgot-pw {
  color: #ffffff;
  text-align: end;
  margin-top: 1rem;
}

.hcp-pw-notifier {
  margin-top: 1rem;
  text-align: center;
  color: #ffffff;
  margin-bottom: 1rem;
}

.hcp-didnt-received {
  color: #ffffff;
  text-align: center;
  margin-top: 1rem;
  display: flex;
  height: fit-content;
  justify-content: space-between;
}

.hcp-resend-button {
  background: #ffffff00 !important;
  border: 2px solid #ffffff !important;
  border-radius: 25px !important;
  color: #ffffff !important;
  border-color: #ffffff !important;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.hcp-didnt-receive-text {
  width: fit-content;
  height: fit-content;
}

.hcp-line {
  border-top: 3px solid #ffffff;
  height: fit-content;
  width: 20%;
  margin-top: 0.6rem;
}

.hcp-signup-container {
  background: linear-gradient(0deg, #ffdada 0%, #ffdada 100%);
  height: 100%;
  min-height: 95vh;
  flex: 1 1 auto;
  padding: 10%;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}

.hcp-signup-title {
  color: #000000;
  font-size: 30px;
  font-style: normal;
}

.allfields {
  font-style: italic;
}

.hcp-signup-phone-label {
  color: #403B3B;
  font-size: 20px;
}

.hcp-signup-button {
  background: #000000 !important;
  border: 2px solid #000000 !important;
  border-radius: 25px !important;
  color: #ffffff !important;
  border-color: #000000 !important;
  margin-top: 1rem;
  margin-bottom: 6rem;
}

.hcp-addmore-button {
  background: #403b3b !important;
  border: 2px solid #403b3b !important;
  border-radius: 25px !important;
  color: #ffffff !important;
  margin-bottom: 1rem;
}

.hcp-med {
  display: flex;
  justify-content: space-between;
}

.select-input {
  border: 2px solid !important;
  border-color: #000000 !important;
}

.hcp-signup-select {
  margin-top: 2.5rem !important;
  border: 2px solid;
  border-color: #000000;
  padding-left: 10px;
  border-radius: 0.7rem !important;
}

.hcp-signup-autocomplete {
  margin-top: 2.5rem !important;
  border: 2px solid;
  border-color: #000000;
  border-radius: 0.7rem !important;
}

.hcp-signup-select-label {
  margin-top: 35px;
  margin-left: 0.4rem;
}

.hcp-selected {
  background: white;
  border-radius: 20px;
  color: #FE7173;
  text-align: center;
}

.hcp-non-selected {
  color: white;
  text-align: center;
}

.patient-selected {
  background: #403B3B;
  border-radius: 20px;
  color: white;
  text-align: center;
}

.patient-non-selected {
  text-align: center;
}

.hcp-home {
  background-color: #FE4A4D;
  box-shadow: 0px 1px 5px gray;
  height: 4rem;
  text-align: center;
  padding-top: 0.8rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.hcp-home-welcome {
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.hcp-home-submission {
  background: linear-gradient(120.72deg, #1F92C4 62.31%, #086287 106.13%);
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  height: fit-content;
  width: 100%;
  padding-bottom: 1rem;
}

.hcp-home-padding-outside {
  padding: 5%;
}

.hcp-home-redemption {
  margin-top: 10%;
  background: linear-gradient(120.72deg, #F9971B 62.31%, #E25500 106.13%);
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  height: fit-content;
  width: 100%;
}

.hcp-home-submission-text {
  text-align: center;
  color: #ffffff;
  font-weight: 800;
  font-size: 28px;
  font-style: normal;
}

.hcp-home-patient-text {
  padding-left: 10%;
  color: #ffffff;
}

.hcp-submission-input {
  padding-left: 10%;
  padding-right: 10%;
}

.hcp-search-button {
  margin-top: 10vh;
  background: #ffffff !important;
  border-radius: 25px !important;
  color: #7fc7e7 !important;
  border-color: #7fc7e7 !important;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.hcp-redeem-button {
  margin-top: 10vh;
  background: #ffffff !important;
  border-radius: 25px !important;
  color: #F6A63F !important;
  border-color: #F6A63F !important;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.hcp-bottom-bar {
  display: flex;
  border-top: 1px solid;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.hcp-padding-bar {
  position: fixed;
  background-color: #ffffff;
  width: 100%;
  bottom: 0;
  z-index: 999;
}

.hcp-transaction-text {
  width: 5rem;
  color: #1f1c6b;
  text-align: center;
  line-height: 1;
  margin-top: 4px;
  font-size: 14px;
}

.submission-product-img {
  max-width: 600px;
  width: 90%;
  display: block;
  margin: auto;
}

.submission-img-container {
  width: 90%;
}

.transaction-product-img {
  max-width: 600px;
  width: 90%;
  display: block;
  margin: auto;
}

.transaction-img-container {
  width: 60%;
}

.redemption-img {
  display: block;
  margin: auto;
  width: 50%;
}

.hcp-img {
  display: block;
  margin: auto;
}

.hcp-home-logo {
  width: 100%;
}

/* .modal {
  font-size: 12px;
} */

.modal>.header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}

.modal>.content {
  width: 100%;
  padding: 10px 5px;
}

.modal>.actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}

.modal>.close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.css-1wnsr1i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80% !important;
  border: 0 !important;
  background-color: #fe7173;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  padding: 32px;
  border-radius: 20px;
}

.hcp-alert {
  margin-top: 1rem;
  text-align: center;
  color: #ffffff;
  font-weight: 600;
}

.hcp-register-button {
  background: #ffffff !important;
  border-radius: 25px !important;
  color: #fe7173 !important;
  margin-top: 1rem;
}

.align-right {
  margin-top: -1rem;
  margin-right: -1rem;
  float: right;
}

.hcp-left-arrow {
  height: 1.5rem;
  margin-top: 0.1rem;
  margin-right: 1rem;
  margin-left: 1rem;
}

.hcp-body-padding {
  padding-left: 5%;
  padding-right: 5%;
}

.hcp-patient-profile {
  background-color: #e9f7fe !important;
  border-radius: 20px !important;
}

.hcp-transaction-history {
  background-color: #e9f7fe !important;
}

.hcp-pending-redemption {
  background-color: #9EDFFF !important;
  border-radius: 20px !important;
}

.hcp-submission-button {
  border-radius: 15px !important;
  border-color: #000000 !important;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 30%;
  margin-right: 0.5rem;
}

.hcp-submission-history-button {
  display: flex;
  justify-content: space-between;
}

.hcp-submission-history-details {
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  background-color: #e9f7fe;
  border-radius: 20px;
  padding: 1rem;
  margin-bottom: 2.5rem;
}

.hcp-float-right {
  position: relative;
  margin-right: 0;
  margin-right: 1rem;
  height: 1rem;
  margin-bottom: 2rem !important;
}

.hcp-align-right {
  position: absolute;
  right: 1;
  margin-right: 0;
  margin-right: 1rem;
}

.DatePicker__input {
  background: #fff;
  border: 1px solid #000 !important;
  padding: 0.4em 0.8em;
  font-family: inherit;
  text-align: center;
  font-size: 12px;
}

.hcp-transaction-history-details {
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  background-color: #e9f7fe;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

.hcp-image-icon {
  height: fit-content;
}

.hcp-expanded-details {
  background-color: #e9f7fe;
  overflow-y: hidden;
  position: relative;
}

.hcp-flexing {
  display: flex;
  margin-bottom: 1rem;
}

.DatePicker {
  position: absolute !important;
  display: inline-block;
  z-index: 100;
  right: 1px !important;
}

.hcp-space {
  margin-right: 1rem;
}

.hcp-header {
  text-align: center;
}

.hcp-label {
  border-bottom: 1px solid #838383;
}

.hcp-small-font {
  font-size: small;
}

.hcp-table {
  text-align: center;
}

.hcp-table-width {
  width: 100%;
  margin-bottom: 1rem;
}

.DayPickerInput {
  float: right;
  /* display: inline-block; */
}

.hcp-calendar-top-bar {
  display: flex;
  justify-content: stretch;
}

.hcp-header-width {
  width: 50%;
  text-align: center;
  border-bottom: 1px solid;
  padding-top: 0.6rem;
  height: 2.5rem;
}

input {
  margin: 0;
  width: 5.5rem;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.hcp-terms-and-condition-frame {
  padding: 10% 10% 10% 10%;
  background-color: #fee9e9;
  min-height: 100vh;
}

.hcp-terms-text {
  margin-top: 2rem;
  height: 50vh;
  overflow-y: scroll;
  text-align: justify;
}

.hcp-agree {
  margin-top: 1rem;
  display: flex;
}

.hcp-agree-text {
  margin-top: 1rem;
}

.hcp-button-agree {
  position: relative;
}

.hcp-continue-button {
  margin-top: 1rem;
}

.hcp-checkout-details {
  background-color: #e9f7fe;
  overflow-y: hidden;
  position: relative;
  border-radius: 20px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.hcp-checkout-button {
  background: #403b3b !important;
  border: 2px solid #403b3b !important;
  border-radius: 25px !important;
  color: #ffffff !important;
  border-color: #403b3b !important;
  margin-top: 1rem;
  width: 80% !important;
  padding-left: 30% !important;
  padding-right: 30% !important;
  position: fixed;
  margin-left: -40%;
  bottom: 6rem;
  z-index: 9999;
}

.hcp-checkout-div {
  position: absolute;
  width: 100% !important;
  background-color: #ffffff;
}

.hcp-no-button {
  background: #ffffff00 !important;
  border-radius: 25px !important;
  border-color: #ffffff;
  border: 3px solid !important;
  color: #ffffff !important;
  margin-top: 1rem;
}

.hcp-table-label {
  width: 55%;
}

.hcp-table-white-text {
  color: #ffffff;
}

.hcp-redemption-code-text {
  bottom: 9.5rem !important;
  position: absolute;
  padding-top: 1rem;
  width: 100%;
  color: #ffffff;
  padding-left: 10%;
  padding-right: 10%;
  background-color: #ffffff;
}

.hcp-checkout-inside {
  display: flex;
}

.hcp-verify-button {
  background: #ffffff !important;
  border: 2px solid #403b3b !important;
  border-radius: 10px !important;
  color: #000000 !important;
  border-color: #ffffff !important;
  margin-top: 0.1rem;
  width: fit-content !important;
  margin-left: 74%;
  bottom: 6rem;
  margin-top: 3px;
  height: 2.5rem !important;
  z-index: 9999;
}

.css-x5c959-MuiInputBase-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.hcp-code-div {
  position: absolute;
  right: 4rem;
  width: fit-content !important;
}

.hcp-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  text-align: center;
}

/* .modal {
  padding-top: 100%;
  background: rgba(0, 0, 0, 0.9);
  transition: all ease 1s;
} */

.hcp-close-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.hcp-alert2 {
  margin-top: 1rem;
  text-align: center;
  color: #000000;
  font-weight: 600;
}

.hcp-submission-registered-quantity-text {
  display: flex;
}

.hcp-minus-icon {
  height: 2rem;
  margin-right: 1rem;
}

.hcp-plus-icon {
  height: 2rem;
  margin-left: 1rem;
}

.hcp-quantity-text {
  border: 1px solid #000000;
  padding: 5px;
  border-radius: 10px;
  width: 4rem;
  text-align: center;
}

.hcp-table-width-submission {
  width: 100%;
  margin-bottom: 1rem;
  text-align: center;
}

.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
  background-color: #C2EBFF;
}

.hcp-table-header-text {
  border-bottom: 1px solid #a1a1a1;
}

.hcp-table-header {
  text-align: center;
}

.hcp-verify-button-instant {
  background: #403b3b !important;
  border: 2px solid #403b3b !important;
  border-radius: 10px !important;
  color: #ffffff !important;
  width: fit-content !important;
  margin-left: 74%;
  bottom: 6rem;
  margin-top: 3px;
  height: 2.5rem !important;
  z-index: 9999;
}

.hcp-submit-button {
  background: #403b3b !important;
  border: 2px solid !important;
  border-radius: 30px !important;
  color: #ffffff !important;
  border-color: #403b3b !important;
  margin-top: 1rem;
  width: 15rem !important;
  margin-bottom: 4vh;
}

.hcp-whole-body {
  /* overflow-y: scroll; */
  /* height: 80vh; */
  padding-bottom: 15vh;
}

.hcp-credit-body {
  overflow-y: scroll;
  height: 80vh;
  padding-bottom: 5vh;
}

.hcp-update-button {
  background: #403b3b !important;
  border: 2px solid #403b3b !important;
  border-radius: 25px !important;
  color: #ffffff !important;
  margin-top: 1rem;
  margin-bottom: 6rem;
}

.hcp-update-select {
  margin-top: 2.5rem;
  height: 3rem;
  border: 2px solid;
  border-color: #000000;
}

.hcp-instant-redemption-title {
  margin-left: 1rem;
}

.hcp-flex-submission {
  display: flex;
}

.hcp-instant-redemption-text {
  text-align: left;
  color: #ffffff;
}

.hcp-not-button {
  background: #00000000 !important;
  border-radius: 25px !important;
  color: #ffffff !important;
  margin-top: 1rem;
  border: 3px solid #ffffff !important;
}

.hcp-info-instant-redemption-header-text {
  color: #ffffff;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.hcp-submission-checkout-table {
  padding: 2%;
}

.hcp-bottom-border-table {
  border-bottom: 1px solid #403b3b80;
  vertical-align: bottom;
}

.hcp-checkout-table-header {
  width: 3.1rem;
}

.patient-welcome-wholepage {
  height: 50vh;
  padding-top: 10vh;
}

.patient-welcome-subtitle {
  text-align: center;
  color: #2f9bca;
}

.patient-welcome-background {
  height: 50vh;
  border-radius: 60% 60% 0% 0% / 30% 30% 60% 40%;
  background: #e9f7fe;
  padding-top: 20%;
}

.patient-login-button {
  margin-top: 20vh;
  background: #00000000 !important;
  border-radius: 25px !important;
  color: #403b3b !important;
  border-color: #403b3b !important;
  border: 3px solid !important;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.patient-resendotp-button {
  margin-top: 20vh;
  background: #00000000 !important;
  border-radius: 25px !important;
  color: white !important;
  border-color: white !important;
  border: 3px solid !important;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.patient-signup-button {
  margin-top: 20vh;
  border-radius: 25px !important;
  color: #ffffff !important;
  background-color: #403b3b !important;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.patient-otp-button {
  margin-top: 20vh;
  background: #ffffff !important;
  border-radius: 25px !important;
  color: #66BCE2 !important;
  border-color: #66BCE2 !important;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.patient-login-page {
  background-color: #66bce2;
  height: 100%;
}

.patient-otp-page {
  background-color: #E9F7FE;
  height: 100%;
}

.patient-login-container {
  background: #e9f7fe;
  height: 100%;
  min-height: 95vh;
  flex: 1 1 auto;
  padding: 10%;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}

.patient-otp-container {
  background: linear-gradient(0deg, #C0EAFF 0%, #66BCE2 100%);
  min-height: 95vh;
  padding: 10%;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}

.patient-didnt-received {
  color: #000000;
  text-align: center;
  margin-top: 1rem;
  display: flex;
  height: fit-content;
  justify-content: space-between;
}

.patient-line {
  border-top: 2px solid #000000;
  height: fit-content;
  width: 20%;
  margin-top: 0.6rem;
}

.hcp-signup-button {
  background: #403b3b !important;
  border: 2px solid #403b3b !important;
  border-radius: 25px !important;
  color: #ffffff !important;
  margin-top: 1rem;
  margin-bottom: 6rem;
}

.patient-terms-and-condition-frame {
  padding: 10% 10% 10% 10%;
  background-color: #e9f7fe;
  min-height: 100vh;
}

.patient-tutorial-text {
  text-align: left;
  font-style: italic;
  font-size: 12px;
}

.patient-signup-video {
  width: 80;
  height: 60%;
  background-color: #c4c4c4;
}

.patient-play-btn {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.patient-home {
  background-color: #0091CE;
  box-shadow: 0px 1px 5px gray;
  height: 4rem;
  text-align: center;
  padding-top: 0.8rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.patient-home-submission-table {
  color: #7fc7e7 !important;
  width: 85%;
  background-color: #ffffff !important;
  border-radius: 20px !important;
}

.patient-home-redemption-table {
  color: #F59924 !important;
  width: 85%;
  background-color: #ffffff !important;
  border-radius: 20px !important;
}

.patient-home-redemption {
  margin-top: 10%;
  padding-bottom: 1rem;
  background: linear-gradient(120.72deg, #F9971B 62.31%, #E25500 106.13%);
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  height: fit-content;
  width: 100%;
}

.patient-transaction-text {
  width: 5rem;
  color: #1f1c6b;
  text-align: center;
  line-height: 1;
  margin-top: 8px;
  font-size: 14px;
}

.patient-inbox {
  margin-top: 0.27rem;
}

.patient-help {
  margin-top: 0.27rem;
}

.patient-text-help {
  margin-top: 0.3rem;
}

.patient-pending-submission {
  padding: 1rem;
  border-radius: 30px;
}

.patient-alert {
  margin-top: 1rem;
  text-align: center;
  color: #000000;
  font-weight: 600;
}

.patient-confirm-button {
  background: #00000000 !important;
  border-radius: 25px !important;
  color: #000000 !important;
  border: 2px solid #000000 !important;
  margin-top: 1rem;
}

.patient-confirm-submission-table {
  width: 100%;
}

.patient-unread-message-text {
  margin-left: 1.9rem;
}

.patient-inbox-row {
  display: flex !important;
  background-color: #e9f7fe !important;
  padding: 1rem;
  justify-content: space-between;
  border-radius: 30px;
  margin-bottom: 1rem;
}

.circle {
  margin-right: 0.5rem;
  width: 130px;
  height: 70px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
  background: blue;
}

.patient-inbox-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.patient-text-msg {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.patient-transaction-rules-body {
  margin-top: 2%;
}

.patient-transaction-rules-expand {
  background-color: #e9f7fe;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

.patient-transaction-rules-row {
  padding-left: 1rem;
  padding-right: 1rem;
}

.patient-transaction-rules-bg {
  background-color: #e9f7fe;
  padding-bottom: 1rem;
}

.patient-transaction-rules-margin {
  margin-bottom: 1rem;
}

.patient-transaction-rules-text-align {
  text-align: left;
  margin-left: 0.5rem;
}

.patient-transaction-history-text {
  padding-left: 1rem;
  font-weight: bold;
}

.patient-transaction-history-table {
  width: 100%;
  text-align: center;
}

.patient-transaction-rules-table-header {
  border-bottom: 1px solid #403b3b80;
}

.patient-transaction-rules-padding {
  padding-left: 1rem;
  padding-right: 1rem;
}

.patient-change-height {
  margin-top: 0.1rem;
}

.patient-transaction-rules-height {
  overflow-y: scroll;
  height: 67vh;
}

.patient-hospital-transaction-rules {
  height: 6px;
}

.patient-transaction-rules-table-total {
  width: 100%;
  text-align: center;
  border: 2px solid #c2ebff;
  margin-top: 0.5rem;
}

.patient-total-width {
  width: 33%;
}

.patient-submitted-width {
  width: 33%;
}

.patient-table-header-redemption {
  width: 66%;
}

.patient-okay-button {
  border: 3px solid #403b3b !important;
  background-color: #00000000 !important;
  border-radius: 30px !important;
}

.patient-hotline {
  display: flex;
  justify-content: space-evenly;
  background-color: #e9f7fe;
  padding: 1rem;
  border-radius: 30px;
}

.patient-hotline-padding {
  padding-left: 1rem;
  padding-right: 1rem;
}

.patient-phone-img {
  width: 3.8rem;
  margin-left: 1rem;
}

.patient-contact-customer-service-text {
  margin-left: 1rem;
  text-align: left;
}

.patient-hotline-bg-color {
  background-color: #e9f7fe;
  padding-bottom: 1rem;
  border-radius: 30px;
}

.patient-contact-btn {
  background-color: #403b3b;
  color: #ffffff;
  padding: 1rem;
  border-radius: 30px;
  width: 10rem;
}

.patient-ctc-btn {
  margin-bottom: 0;
  font-weight: 500;
}

.admin-nav-link {
  color: white;
  text-decoration: none;
}

.admin-nav-link:hover {
  color: white;
}

.active {
  font-weight: bold !important;
}

.admin-sidebar {
  min-height: 100vh;
  min-width: 20%;
  background: linear-gradient(180deg, #FEBBBC 0%, #FE7072 100%);
}

.sidebar-menu-text {
  font-size: 18px;
  color: #ffffff;
  width: 100%;
}

.sidebar-menu-bottom-line {
  padding-top: 1rem;
  list-style-type: none;
  border-bottom: 0.5px solid #ffffff !important;
  width: 90%;
  padding-bottom: 1rem;
}

.sidebar-menu-bottom-noline {
  padding-top: 1rem;
  list-style-type: none;
  padding-bottom: 1rem;
  width: 90%;
}

.sidebar-menu-bottom-topline {
  padding-top: 1rem;
  padding-bottom: 1rem;
  list-style-type: none;
  width: 90%;
  border-top: 0.5px solid #ffffff !important;
}

.validation-title {
  font-size: 25px;
  font-weight: 700;
  min-width: 15%;
  text-align: center;
}

.validation-title-selected {
  font-weight: 900;
  font-size: 25px;
  text-decoration: underline;
  min-width: 15%;
  text-align: center;
}

.validation-vertical-line {
  border-left: 4px solid #000000;
}

.admin-cancel-btn {
  color: #979797 !important;
  background: #F2F2F2 !important;
  border: 2px solid #979797 !important;
}

.dashboard-button {
  background: #F8F8F8 !important;
  border: 2px solid #979797 !important;
  border-radius: 10px !important;
  color: #979797 !important;
  align-self: center;
}

.dashboard-button-selected {
  background: #403B3B;
  border-radius: 10px;
  color: #FFFFFF;
}

.admin-dashboard-text {
  font-size: 29px;
  color: #403B3B;
}

.admin-dashboard-subtitle {
  font-size: 20px;
  color: #403B3B;
}

.admin-button {
  background-color: #403B3B !important;
  border-color: #403B3B !important;
}

.admin-dashboard-detail {
  margin-left: 17%;
  padding: 2rem;
  width: 10%;
}

.admin-dashboard-grid {
  width: 18vw;
  background-color: #0093D1;
  color: white;
  border-radius: 30px;
  height: 15rem;
}

.admin-dashboard-border {
  border: 3px solid #DADADA;
  border-radius: 30px;
  padding: 3%;
}

#circle {
  width: 50px;
  height: 50px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  background: #e7e7e7;
}

.report-header {
  background: #DEDDDD;
}

.DayPickerInput {
  display: inline-block;
  margin-top: 1rem;
  margin-right: 1rem;
}

.col-md-6 {
  flex: 0 0 auto;
  width: 65% !important;
}

th,
tr {
  border: 1px solid #dadada;
}

.css-1afqklp {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: fit-content !important;
  background-color: #fe7173;
  border-radius: 20px;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  padding: 32px;
}

.dot {
  margin: 0.1rem;
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

/* admin */
#admin-login {
  background-color: #E9F7FE;
  height: 100vh;
  width: 100vw;
  padding-top: 30vh;
  text-align: center;
  overflow: hidden;
  box-sizing: border-box;
}

#admin-login #admin-login-container {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
}

#admin-login #admin-login-container #admin-image-container {
  font-weight: bold;
  margin-right: 5vw;
  align-self: center;
}

#admin-login #admin-login-container #admin-image-container #image-container {
  max-width: 500px;
}

#admin-login #admin-login-container #admin-login-form {
  background-color: white;
  max-width: 400px;
  padding: 30px;
}

.inventory-modal {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  background-color: white;
  overflow-y: auto;
  height: 90%;
  padding: 1% 5%;
}

/* tablet version */
@media screen and (min-width: 768px) {
  .button-container {
    padding: 0% 25% 0% 25%;
  }

  .hcp-terms-and-condition-frame {
    padding: 10% 20% 10% 20%;
  }

  .hcp-login-page {
    background-color: #FE7072;
  }

  .hcp-login-title {
    text-align: center;
  }

  .form-container {
    padding: 0% 20% 0% 20%;
  }

  .red-modal {
    width: 50%;
  }

  .blue-modal {
    width: 50%;
  }

  .hcp-home-padding-outside {
    padding: 5% 20% 5% 20%;
  }

  .hcp-bottom-bar {
    padding: 0% 20% 0% 20%;
  }

  .hcp-home-submission {
    padding: 0% 7% 3% 7%;
  }

  .hcp-home-redemption {
    padding: 0% 7% 3% 7%;
  }

  .tutorial-modal-1 {
    width: 50%;
  }

  .tutorial-modal-2 {
    width: 50%;
  }

  .tutorial-modal-3 {
    width: 50%;
  }

  .tutorial-modal-4 {
    width: 50%;
  }

  .page-title {
    text-align: center;
    display: initial;
  }

  .hcp-left-arrow {
    margin-left: 3%;
    margin-top: 3%;
  }

  .page-body {
    padding: 0% 20% 0% 20%;
  }

  .brand-info-page {
    padding: 0% 3% 0% 3%;
  }

  .brand-info-item {
    display: flex;
  }

  .hcp-transaction-history {
    padding: 0% 10% 0% 10%;
  }

  .hcp-signup-page {
    background-color: #FFDADA;
  }

  .hcp-signup-title {
    text-align: center;
  }

  .sign-up-subtitle {
    font-size: 15px;
    padding-top: 3%;
  }

  .instant-redemption-modal {
    height: 80vh;
  }

  .hcp-submission-checkout-table {
    padding: 2% 10%;
  }

  .patient-terms-and-condition-frame {
    padding: 10% 20% 10% 20%;
  }

  .patient-login-page {
    background-color: #E9F7FE;
  }

  .patient-otp-page {
    background-color: #66BCE2;
  }

  .patient-home-redemption {
    padding: 0% 7% 3% 7%;
  }

  .submission-img-container {
    width: 60%;
  }

  .transaction-img-container {
    width: 50%;
  }
}

/* desktop version */
@media screen and (min-width: 992px) {
  .hcp-welcome-logo {
    width: 20%;
  }

  .hcp-welcome-container {
    padding-top: 10vh;
    height: 50vh;
  }

  .hcp-welcome-background {
    min-height: 50vh;
    padding-top: 13%;
  }

  .hcp-terms-and-condition-frame {
    padding: 5% 20% 0% 20%;
  }

  .hcp-terms-text {
    height: 40vh !important;
  }

  .hcp-login-container {
    padding: 0% 10% 0% 10%;
  }

  .hcp-home-padding-outside {
    padding: 5% 30% 5% 30%;
  }

  .hcp-bottom-bar {
    padding: 0% 30% 0% 30%;
  }

  .hcp-home-submission {
    padding: 0% 10% 3% 10%;
  }

  .hcp-home-redemption {
    padding: 0% 10% 3% 10%;
  }

  .page-body {
    padding: 0% 30% 0% 30%;
  }

  .brand-info-page {
    padding: 0% 10% 0% 10%;
  }

  .patient-welcome-background {
    padding-top: 5%;
  }

  .patient-terms-and-condition-frame {
    padding: 5% 20% 0% 20%;
  }

  .patient-home-redemption {
    padding: 0% 10% 3% 10%;
  }

  .submission-img-container {
    width: 50%;
  }

  .transaction-img-container {
    width: 30%;
  }

  .redemption-img {
    width: 40%;
  }
}